import styled from "styled-components"
import Title from "./Title"
import { device } from "../styles/stylesConfig"
import { CSSProperties, memo, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import { ThemeContext } from "../contexts/theme/ThemeContext"
import { useTranslation } from "react-i18next"

const Container = styled.div`
    width: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 2;
    border-bottom: 1px solid rgba(27, 48, 100, 0.3);
`

const ContentContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    @media ${device.lg} {
        width: 100%;

        & > div > h1 {
            text-align: center !important;
        }

    }
`

const DataContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
`

interface Props {
    title: string;
    subtitle: string;
    image?: string;
    imageStyle?: CSSProperties
    children?: any
}

const ProductSectionComponent = ({ title, subtitle, image, imageStyle, children }: Props) => {

    const location = useLocation()

    const {
        theme,
    } = useContext(ThemeContext);

    const { i18n } = useTranslation();

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, i18n.language, theme])


    return (

        <Container>

            <ContentContainer>

                <Title
                    title={title}
                    subtitle={subtitle}
                    titleColor="#004bb5"
                    textAlign="start"
                    titleLighter
                />

                <DataContainer>

                    {image && <Image className="utils__fade-in-fast-top" src={require(`../images/${image}`)} alt={title} style={{ ...imageStyle }} loading="lazy" />}

                    {children && children}

                </DataContainer>

            </ContentContainer>


        </Container>

    )
}

const ProductSection = memo(ProductSectionComponent);

export default ProductSection;