import { ContactFormProps, QuotatorFormProps } from "../interfaces/ContactInterfaces";
import { BrandsCarrouselProps, IntroImagesProps, ProfileProps, PvcColorProps, WorkProps } from "../interfaces/WorkInterfaces";

export const introImagesList: IntroImagesProps[] = [
    {
        id: 1,
        title: 'CALIDAD Y DISEÑO',
        subtitle: 'PARA TU PROYECTO',
        textColor: '#ff0c19',
        image: require('../images/intro1.webp')
    },
    {
        id: 2,
        title: 'MAS DE 25 AÑOS',
        subtitle: 'DE EXPERIENCIA',
        textColor: '#ff0c19',
        image: require('../images/intro2.webp'),
    },
    {
        id: 3,
        title: 'LA SEGURIDAD',
        subtitle: 'PARA TU HOGAR',
        textColor: '#ff0c19',
        image: require('../images/intro3.webp'),
    },
    {
        id: 4,
        title: 'ABERTURAS DE ',
        subtitle: 'ALUMINIO Y PVC',
        textColor: '#ff0c19',
        image: require('../images/intro4.webp'),
    },
]

export const initialContactForm: ContactFormProps = {
    message: '',
}

export const initialQuotatorForm: QuotatorFormProps = {
    name: '',
    email: '',
    phone: '',
    locality: '',
    workStatus: 'Proyecto',
    message: '',
}

export const fieldIncompleteContactForm = {
    message: false,
}

export const fieldIncompleteQuotatorForm = {
    name: false,
    email: false,
    phone: false,
    locality: false,
}

export const initialStateWork: WorkProps = {
    id: 0,
    name: '',
    goTo: '',
    logEvent: '',
    images: []
}


export const worksList: WorkProps[] = [
    {
        id: 0,
        name: 'Casa Newman',
        goTo: '/works/#casanewman',
        logEvent: 'click_obra_casa_newman',
        images: [
            require('../images/casa-newman-1.webp'),
            require('../images/casa-newman-2.webp'),
            require('../images/casa-newman-3.webp'),
            require('../images/casa-newman-4.webp'),
            require('../images/casa-newman-5.webp'),
            require('../images/casa-newman-6.webp'),
        ]
    },
    {
        id: 1,
        name: 'Casa V',
        goTo: '/works/#casav',
        logEvent: 'click_obra_casa_v',
        images: [
            require('../images/casa-v-1.webp'),
            require('../images/casa-v-2.webp'),
            require('../images/casa-v-3.webp'),
        ]
    },
    {
        id: 2,
        name: '',
        goTo: '/works/#ezequielcataneo',
        logEvent: 'click_obra_ezequiel_cataneo',
        images: [
            require('../images/ezequiel-cataneo-1.webp'),
            require('../images/ezequiel-cataneo-2.webp'),
            require('../images/ezequiel-cataneo-3.webp'),
            require('../images/ezequiel-cataneo-4.webp'),
            require('../images/ezequiel-cataneo-5.webp'),
            require('../images/ezequiel-cataneo-6.webp'),
            require('../images/ezequiel-cataneo-7.webp'),
        ]
    },
    {
        id: 3,
        name: '',
        goTo: '/works/#nordeltagolf',
        logEvent: 'click_obra_nordelta_golf',
        images: [
            require('../images/nordelta-golf-1.webp'),
            require('../images/nordelta-golf-2.webp'),
            require('../images/nordelta-golf-3.webp'),
            require('../images/nordelta-golf-4.webp'),
        ]
    },
    {
        id: 4,
        name: '',
        goTo: '/works/#sansebastian',
        logEvent: 'click_obra_san_sebastian',
        images: [
            require('../images/san-sebastian-1.webp'),
            require('../images/san-sebastian-2.webp'),
            require('../images/san-sebastian-3.webp'),
            require('../images/san-sebastian-4.webp'),
        ]
    },
    {
        id: 5,
        name: '',
        goTo: '/works/#audi',
        logEvent: 'click_obra_audi',
        images: [
            require('../images/audi-1.webp'),
            require('../images/audi-2.webp'),
            require('../images/audi-3.webp'),
            require('../images/audi-4.webp'),
            require('../images/audi-5.webp'),
        ]
    },
    {
        id: 6,
        name: '',
        goTo: '/works/#bingovarela',
        logEvent: 'click_obra_bingo_varela',
        images: [
            require('../images/bingo-varela-1.webp'),
            require('../images/bingo-varela-2.webp'),
            require('../images/bingo-varela-3.webp'),
        ]
    },
    {
        id: 7,
        name: '',
        goTo: '/works/#malvinas',
        logEvent: 'click_obra_municipalidad_malvinas',
        images: [
            require('../images/municipalidad-malvinas-1.webp'),
            require('../images/municipalidad-malvinas-2.webp'),
        ]
    },
]


export const BrandsCarrouselList: BrandsCarrouselProps[] = [
    {
        id: 0,
        image: require('../images/rehau-blanco.png'),
    },
    {
        id: 1,
        image: require('../images/hydro-blanco.png'),
        customStyle: {
            height: 80,
            width: 80,
        }
    },
    {
        id: 2,
        image: require('../images/aluar-blanco.png'),
        customStyle: {
            height: 70,
            width: 70,
        }
    },
    {
        id: 3,
        image: require('../images/ok-industrial.png'),
        customStyle: {
            height: 60,
            width: 60,
        }
    },
    {
        id: 4,
        image: require('../images/disvial.png'),
    },
    {
        id: 5,
        image: require('../images/gu.png'),
        customStyle: {
            height: 70,
            width: 70,
        }
    },
    {
        id: 6,
        image: require('../images/siegenia.png'),
    },
    {
        id: 7,
        image: require('../images/rial.png'),
        customStyle: {
            height: 80,
            width: 80,
        }
    },
    {
        id: 8,
        image: require('../images/axal.png'),
    },
    {
        id: 9,
        image: require('../images/bestchem.png'),
    },
    {
        id: 10,
        image: require('../images/roto.png'),
    }
]