import styled from "styled-components"
import { memo } from "react"
import WhoAreWe from "./WhoAreWe"

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;

`

const Image = styled.div`
    width: 100%;
    height: 100%;
    background: url(${require('../images/about-house.jpg')}) center no-repeat;
    background-size: cover;
    aspect-ratio: 16 / 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.5;
    }

`


const AboutImageComponent = () => {

    return (

        <Container>

            <Image className="utils__fade-in-fast-top" />

            <WhoAreWe />

        </Container>

    )
}

const AboutImage = memo(AboutImageComponent);

export default AboutImage;