import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import AboutImage from "../components/AboutImage"
import WhoAreWe from "../components/WhoAreWe"
import { allAnimations } from "../effects/Observers"
import ScreenContainer from "../components/ScreenContainer"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../contexts/theme/ThemeContext"

export const AboutScreen = () => {

    const location = useLocation()

    const { i18n } = useTranslation();

    const { theme } = useContext(ThemeContext);

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, i18n.language, theme])

    return (

        <ScreenContainer id="about">

            <AboutImage />

        </ScreenContainer>

    )
}
