import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import Title from "./Title"
import { memo, useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import { useTranslation } from "react-i18next"
import { ThemeContext } from "../contexts/theme/ThemeContext"

const Container = styled.div`
    width: 100%;
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`

const ContentContainer = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 5vh 0 0px;


`
const Subtitle = styled.p<{ color: string }>`
    width: 50%;
    text-align: center;
    line-height: 30px;
    font-family: 'Montserrat', sans-serif;
    color: #2d2d2d;
    color: ${({ color }) => (color)};
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0px;
    }

    @media ${device.lg} {
        width: 80%;
    }

    @media ${device.md} {
        font-size: 14px;
    }
    
    @media ${device.sm} {
        line-height: 25px;
        font-size: 12px;
    }

    @media ${device.xs} {
        line-height: 20px;
    }
`

const Card = styled.div`
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    @media ${device.lg} {
        width: 100px;
        height: 100px;
    }

    @media ${device.md} {
        width: 70px;
        height: 70px;
    }

    @media ${device.sm} {
        width: 40px;
        height: 40px;
    }

    @media ${device.xs} {
        width: 35px;
        height: 35px;
    }
`


const CardRehau = styled(Card)`
    width: 210px;
    height: 210px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    @media ${device.lg} {
        width: 180px;
        height: 180px;
    }

    @media ${device.md} {
        width: 130px;
        height: 130px;
    }

    @media ${device.sm} {
        width: 80px;
        height: 80px;
    }

    @media ${device.xs} {
        width: 60px;
        height: 60px;
    }
`


const WhoAreWeComponent = () => {

    const location = useLocation();

    const { t } = useTranslation();

    const data: any = t('about.descriptions', { returnObjects: true });

    const {
        theme: { colors, dark },
    } = useContext(ThemeContext);

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    return (

        <Container>

            <ContentContainer>

                <Title
                    titleColor="white"
                    title={t('about.title')}
                />

                {
                    data.map((value: string) => {
                        return (
                            <Subtitle color={'white'} key={value} className="utils__fade-in-fast-top">{value}</Subtitle>
                        )
                    })
                }

            </ContentContainer>

        </Container>

    )
}

const WhoAreWe = memo(WhoAreWeComponent);

export default WhoAreWe;