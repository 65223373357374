import { memo, useEffect, useState } from "react"
import { IntroImagesProps } from "../interfaces/WorkInterfaces";
import styled from "styled-components";
import { colors, device, deviceHorizontal } from "../styles/stylesConfig";
import { useLogEvent } from "../hooks/useLogEvent";

const InfoContainer = styled.div` 
    width: 80%;
    text-transform: uppercase;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 20vh;
    bottom: 0;
    margin: auto;

    & > h1 {
        font-size: 62px;
        font-weight: 800;
        letter-spacing: 5px;
        margin-bottom: 0rem;

        @media ${device.xxl} {

            @media ${deviceHorizontal.md} {
                font-size: 50px;
            }
            @media ${deviceHorizontal.sm} {
                font-size: 30px;
                margin-bottom: 3rem;
            }
            @media ${deviceHorizontal.xs} {
                font-size: 20px;
            }
        }
    
        @media ${device.md} {
            font-size: 60px;

            @media ${deviceHorizontal.sm} {
                font-size: 18px;
                margin-bottom: 0rem;
            }

        }
    
        @media ${device.sm} {
            font-size: 30px;
        }
    
        @media ${device.xs} {
            font-size: 30px;
        }
    }

    @media ${device.lg} {
        width: 75%;
    }

    @media ${device.md} {
        width: 80%;
        margin-top: 10vh;

        @media ${deviceHorizontal.sm} {
            margin-top: 0vh;
        }
    }

    &.active {
        & > h1 {
            animation: pulse 1.25s ease;
        }
    }

`


const Brands = styled.img`
    width: 300px;
    height: auto;
    object-fit: cover;
    position: absolute;
    right: 15vw;
    bottom: 5vh;

    &.active {
        animation: pulse 1.25s ease;
    }

    @media ${device.xl} {
        right: 13vw;
    }

    @media ${device.lg} {
        right: 0px;
        left: 0px;
        margin: auto;
        bottom: 10vh;
        width: 250px;
    }
    
    @media ${device.md} {
        width: 200px;
    }

    @media ${device.sm} {
        width: 180px;
        bottom: 12vh;
        right: 0px;
        left: 0px;
        margin: auto;
    }

    @media ${device.xs} {
        bottom: 13vh;
        left: 10vw;
        right: unset;
        margin: unset;
    }

`
//

const ContainerExpo = styled.div` 
    width: 80%;
    display: grid;
    position: absolute;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    object-position: center;
    align-items: center;
    row-gap: 50px;
    left: 0;
    right: 0;
    top: 90px;
    bottom: 0;
    margin: auto;

    @media ${device.xl} {
        top: 90px;
        row-gap: 50px;
    }

    @media ${device.lg} {
        height: 80%;
        grid-template-columns: 1fr;
        grid-auto-rows: unset;
        top: 0px;
        row-gap: 20px;
        justify-items: center;
    }

    @media ${device.sm} {
        top: 50px;
        row-gap: 0px;

    }
`

const InfoExpositionContainer = styled.div` 
    width: 80%;
    text-transform: uppercase;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @media ${device.lg} {
        width: 90%;
        align-items: center;
    }


    & > h1 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 5px;

        @media ${device.lg} {
            text-align: center;
            font-size: 16px;
        }
    
        @media ${device.md} {
            font-size: 14px;
        }
    
        @media ${device.sm} {
            font-size: 14px;
        }
    
        @media ${device.xs} {
            font-size: 10px;
        }
    }

    span {
        font-weight: 700;
    }

    &.active {
        & > h1 {
            animation: pulse 1.25s ease;
        }
    }

`

const ActionExpositionContainer = styled.div` 
    height: fit-content;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    color: ${colors.primary};
    background-color: white;
    padding: 30px;

    @media ${device.sm} {
        padding: 15px;
    }

    &.active {
        animation: pulse 1.25s ease;
    }

    & > h1 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 5px;
        color: ${colors.primary};
        text-transform: uppercase;
        
        @media ${device.lg} {
            font-size: 16px;
            text-align: center;
            width: 100%;
        }
        
        @media ${device.md} {
            font-size: 14px;
        }
        
        @media ${device.sm} {
            font-size: 14px;
        }
        
        @media ${device.xs} {
            font-size: 10px;
        }
    }
    
    span {
        font-weight: 700;
    }

    &.active {
        & > h1 {
            animation: pulse 1.25s ease;
        }
        & > button {
            animation: pulse 1.25s ease;
        }
    }

    button {

        
    @-webkit-keyframes shake-horizontal {
        0%,
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
        10%,
        30%,
        50%,
        70% {
            -webkit-transform: translateX(-5px);
                    transform: translateX(-5px);
        }
        20%,
        40%,
        60% {
            -webkit-transform: translateX(5px);
                    transform: translateX(5px);
        }
        80% {
            -webkit-transform: translateX(3px);
                    transform: translateX(3px);
        }
        90% {
            -webkit-transform: translateX(-3px);
                    transform: translateX(-3px);
        }
    }
    @keyframes shake-horizontal {
        0%,
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
        }
        10%,
        30%,
        50%,
        70% {
            -webkit-transform: translateX(-5px);
                    transform: translateX(-5px);
        }
        20%,
        40%,
        60% {
            -webkit-transform: translateX(5px);
                    transform: translateX(7px);
        }
        80% {
            -webkit-transform: translateX(3px);
                    transform: translateX(3px);
        }
        90% {
            -webkit-transform: translateX(-3px);
                    transform: translateX(-3px);
        }
    }

        cursor: pointer;
        margin: 20px 0px;
        padding: 5px 20px;
        background-color: ${colors.primary};
        border-radius: 15px;
        color: white;
        font-size: 16px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
        transition: all .3s ease;

        &.shake {
            -webkit-animation: shake-horizontal .75s ease-in-out both;
            animation: shake-horizontal .75s ease-in-out both;
        }

        &:hover {
            transform: scale(1.05);
            animation: unset;
        }

        &.active {
            animation: pulse 1.25s ease;
        }

        @media ${device.lg} {
            width: 100%;
            font-size: 16px;
        }
        
        @media ${device.md} {
            margin: 10px 0px;
            font-size: 14px;
        }
        
        @media ${device.sm} {
            font-size: 14px;
        }
        
        @media ${device.xs} {
            font-size: 10px;
        }

        &:last-of-type {
            margin-bottom: 10px;
        }

    }
    
`

const BatevLogoContainer = styled.div` 
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: end;
    justify-items: center;
    margin-top: 70px;

    grid-column-start: 1;
    grid-column-end: 3;

    @media ${device.lg} {
        margin-top: 0px;
        grid-column-start: unset;
        grid-column-end: unset;
    }

    &.active {
        animation: pulse 1.25s ease;
    }

`

const BatevLogo = styled.img`
    width: 150px;
    height: auto;
    object-fit: contain;

    &.active {
        animation: pulse 1.25s ease;
    }
    
    @media ${device.md} {
        width: 100px;
    }

    @media ${device.sm} {
        width: 80px;
    }

    @media ${device.xs} {
        width: 80px;
    }

`
interface Props {
    item: IntroImagesProps;
    animationActive: boolean;
}

const IntroImageItem = ({ item, animationActive }: Props) => {

    const { handleLogEvent } = useLogEvent();

    const [bannerLoad, setbannerLoad] = useState(false);

    const [shake, setShake] = useState(false);

    const [jump, setJump] = useState(false);

    useEffect(() => {

        const handleShake = () => {

            setShake(true);

            setTimeout(() => {
                setShake(false);
            }, 750);

        }

        setTimeout(() => {

            handleShake();

        }, 2000);

        const intervaloShake = setInterval(() => handleShake(), 7000);

        return () => {

            clearInterval(intervaloShake);

        }

    }, []);

    useEffect(() => {

        const handleJump = () => {

            setJump(true);

            setTimeout(() => {
                setJump(false);
            }, 1000);

        }

        setTimeout(() => {

            handleJump();

        }, 4000);

        const intervaloJump = setInterval(() => handleJump(), 10000);

        return () => {

            clearInterval(intervaloJump);
        }

    }, []);

    return (

        <div
            key={item.id}
            className='introduction__image'
        >
            <img src={require(`../images/${item.image}`)}
                loading="eager"
                alt=""
                aria-hidden="true"
                style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                }}
                onLoad={() => { setbannerLoad(true) }}
            />

            {
                (item.id === -1 && bannerLoad) ? (

                    <ContainerExpo>

                        <BatevLogoContainer className={animationActive ? 'active' : ''} >

                            <BatevLogo
                                src={require('../images/aluviher-blanco.png')}
                                loading="eager"
                                alt=""
                                aria-hidden="true"
                                onLoad={() => { setbannerLoad(true) }}
                            />

                            <BatevLogo
                                src={require('../images/rehau-blanco.png')}
                                loading="eager"
                                alt=""
                                aria-hidden="true"
                                onLoad={() => { setbannerLoad(true) }}
                            />

                            <BatevLogo
                                src={require('../images/capba-blanco.png')}
                                loading="eager"
                                alt=""
                                aria-hidden="true"
                                onLoad={() => { setbannerLoad(true) }}
                            />

                        </BatevLogoContainer>

                        <InfoExpositionContainer className={animationActive ? 'active' : ''} key={item.id}>

                            <h1>Capacitación Rehau</h1>

                            <h1><span>Los invitamos a participar<br />de esta segunda charla en<br />casa rehau.</span></h1>

                            <h1 style={{ marginTop: 20, marginBottom: 10, padding: '5px 10px', color: colors.primary, backgroundColor: 'white', borderRadius: 15 }}><span>Cupos limitados</span></h1>

                            <h1 style={{ marginBottom: 5, }}><span>Fecha y hora:</span></h1>

                            <h1>23/01/25</h1>

                            <h1>14:00HS a 17:00HS</h1>

                            <h1 style={{ marginTop: 10, marginBottom: 5, }}><span>Lugar:</span></h1>

                            <h1>Cuyo N°1900 - Martinez</h1>

                        </InfoExpositionContainer>

                        <ActionExpositionContainer>

                            <h1><span>Reserva tu lugar</span></h1>

                            <button
                                style={{ fontWeight: 700 }}
                                className={`${animationActive ? 'active' : ''} ${shake ? 'shake' : ''}`}
                                onClick={() => {
                                    handleLogEvent('click_inscribirme_capac_rehau');
                                    window.open("https://docs.google.com/forms/d/e/1FAIpQLSeZ-ZOA5hp3P8_UwV1FCLRCnjiL5KUJ9cfDSozlfdrH6qMRkw/viewform?usp=header", "_blank");
                                }}
                            >
                                ¡INSCRIBIRME!
                            </button>

                            <h1>o</h1>

                            <h1>Mandanos un whatsapp</h1>

                            <button
                                style={{ fontStyle: 'italic' }}
                                className={`${animationActive ? 'active' : ''} ${shake ? 'shake' : ''}`}
                                onClick={() => {
                                    handleLogEvent('click_whatsapp_capac_rehau');
                                    window.open("https://walink.co/d54724", "_blank");
                                }}
                            >
                                ¡ENVIAR MENSAJE!
                            </button>

                        </ActionExpositionContainer>

                    </ContainerExpo>

                ) : (

                    <>

                        <InfoContainer className={animationActive ? 'active' : ''} key={item.id}>

                            <h1 style={{ color: item.textColor }}>{item.title}</h1>

                            <h1>{item.subtitle}</h1>

                        </InfoContainer>

                        <Brands
                            className={animationActive ? 'active' : ''}
                            src={require('../images/rehau-hydro.webp')}
                            loading="lazy"
                            alt=""
                            aria-hidden="true"
                        />

                    </>

                )
            }

        </div>

    )
}

const MemorizedIntroImageItem = memo(IntroImageItem);

export default MemorizedIntroImageItem;
